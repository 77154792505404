<template>
  <div class="notice">
    <img class="partyLogo" :src="require('@/assets/img/partyLogo.png')" alt=""/>
    <div class="workGoal">
      <div class="module">
        <div class="title">
          <span>最新公告<img style="padding-left:5px;padding-bottom:2px;" :src="require('@/assets/img/partyNew.png')" alt=""/></span>
        </div>
        <div v-for="(item, index) in newList" :key="index" @click="navToFun(item.id, item.typeName)">
          <div class="content">
            <van-image :src="item.typeName == '党务公开' ? partyOpen : (item.typeName == '居务公开' && (villageType == 2 || villageType == 3)) ? houseOpen : (item.typeName == '村务公开' && villageType == 4) ? villageOpen : financialOpen" class="content-img">
            </van-image>
            <van-image :src="item.community == 0 ? street : community" class="content-logo"/>
            <div class="content-text">
              <div class="top-text">{{item.title}}</div>
              <div class="center-text">{{item.openDesc}}</div>
              <div class="bottom-text">
                <div class="left-time">
                  <img :src="require('@/assets/img/party_time.png')" alt="" />
                  <span>{{item.create}}</span>
                </div>
                <div class="right-look">
                  <img :src="require('@/assets/img/party_look.png')" alt="" />
                  <span><i>{{item.checkNum ? item.checkNum : 0}}</i> 人看过</span>
                </div>
              </div>
            </div>
            <van-icon name="arrow" color="#D1D1D6" class="content-icon"/>
          </div>
          <div class="divider" v-if="index !== newList.length - 1"></div>
        </div>
      </div>
      <div class="module">
        <div class="title" @click="workTargetList(partyId,'党务公开')">
          <span>党务公开</span>
          <template v-if="partyList.length > 0">
            <span>更多</span>
            <van-icon name="arrow" color="#D1D1D6"/>
          </template>
        </div>
        <div v-for="(item, index) in partyList" :key="index" @click="navToFun(item.id, item.typeName)">
          <div class="content">
            <van-image :src="partyOpen" class="content-img">
            </van-image>
            <van-image :src="item.community == 0 ? street : community" class="content-logo"/>
            <div class="content-text">
              <div class="top-text">{{item.title}}</div>
              <div class="center-text">{{item.openDesc}}</div>
              <div class="bottom-text">
                <div class="left-time">
                  <img :src="require('@/assets/img/party_time.png')" alt="" />
                  <span>{{item.create}}</span>
                </div>
                <div class="right-look">
                  <img :src="require('@/assets/img/party_look.png')" alt="" />
                  <span><i>{{item.checkNum ? item.checkNum : 0}}</i> 人看过</span>
                </div>
              </div>
            </div>
            <van-icon name="arrow" color="#D1D1D6" class="content-icon"/>
          </div>
          <div class="divider" v-if="index !== partyList.length - 1"></div>
        </div>
      </div>
      <div class="module">
        <div class="title" @click="workTargetList(villageId,'村务公开')">
          <span>{{(villageType == 2 || villageType == 3) ? '居务公开' : '村务公开'}}</span>
          <template v-if="villageList.length > 0">
            <span>更多</span>
            <van-icon name="arrow" color="#D1D1D6"/>
          </template>
        </div>
        <div v-for="(item, index) in villageList" :key="index" @click="navToFun(item.id, item.typeName)">
          <div class="content">
            <van-image :src="(villageType == 2 || villageType == 3) ? houseOpen : villageOpen" class="content-img">
            </van-image>
            <van-image :src="item.community == 0 ? street : community" class="content-logo"/>
            <div class="content-text">
              <div class="top-text">{{item.title}}</div>
              <div class="center-text">{{item.openDesc}}</div>
              <div class="bottom-text">
                <div class="left-time">
                  <img :src="require('@/assets/img/party_time.png')" alt="" />
                  <span>{{item.create}}</span>
                </div>
                <div class="right-look">
                  <img :src="require('@/assets/img/party_look.png')" alt="" />
                  <span><i>{{item.checkNum ? item.checkNum : 0}}</i> 人看过</span>
                </div>
              </div>
            </div>
            <van-icon name="arrow" color="#D1D1D6" class="content-icon"/>
          </div>
          <div class="divider" v-if="index !== villageList.length - 1"></div>
        </div>
      </div>
      <div class="module">
        <div class="title" @click="workTargetList(financialId,'财务公开')">
          <span>财务公开</span>
          <template v-if="financialList.length > 0">
            <span>更多</span>
            <van-icon name="arrow" color="#D1D1D6"/>
          </template>
        </div>
        <div v-for="(item, index) in financialList" :key="index" @click="navToFun(item.id, item.typeName)">
          <div class="content">
            <van-image :src="financialOpen" class="content-img">
            </van-image>
            <van-image :src="item.community == 0 ? street : community" class="content-logo"/>
            <div class="content-text">
              <div class="top-text">{{item.title}}</div>
              <div class="center-text">{{item.openDesc}}</div>
              <div class="bottom-text">
                <div class="left-time">
                  <img :src="require('@/assets/img/party_time.png')" alt="" />
                  <span>{{item.create}}</span>
                </div>
                <div class="right-look">
                  <img :src="require('@/assets/img/party_look.png')" alt="" />
                  <span><i>{{item.checkNum ? item.checkNum : 0}}</i> 人看过</span>
                </div>
              </div>
            </div>
            <van-icon name="arrow" color="#D1D1D6" class="content-icon"/>
          </div>
          <div class="divider" v-if="index !== financialList.length - 1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import workItem from "../workGoals/workItem.vue";
import {formatterDate} from '@/utils/utils'
import {newDate} from "../../utils/utils";

export default {
  components: {
    topBar,
    workItem,
  },
  data() {
    return {
      partyList: [],
      villageList: [],
      financialList: [],
      newList: [],
      partyId: 0,
      villageId: 0,
      financialId: 0,
      villageType: '',
      street: require('@/assets/img/street.png'),
      community: require('@/assets/img/community.png'),
      partyOpen: require('@/assets/img/party_open.png'),
      houseOpen: require('@/assets/img/house-open.png'),
      villageOpen: require('@/assets/img/village-open.png'),
      financialOpen: require('@/assets/img/financial_open.png')
    };
  },
  methods: {
    getType(name) {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/getByNameWithCode"),
        method: "post",
        params: this.$http.adornParams({
          code: 'affairsType',
          name: name,
          orgId: this.$orgId
        }),
      }).then(({ data }) => {
            if (data && data.code == 0) {
              if (name == '党务公开') {
                this.partyId = data.sysDict.id;
              }
              if (name == '村务公开') {
                this.villageId = data.sysDict.id;
              }
              if (name == '财务公开') {
                this.financialId = data.sysDict.id;
              }
              this.getDataList(data.sysDict.id, name)
              if (this.partyId && this.villageId && this.financialId) {
                let totalId = this.partyId + ',' + this.villageId + ',' + this.financialId
                this.getDataList(totalId, '全部')
                // name = '全部'
              }
            }
          }
      );
    },
    // 判断村务还是居务
    getInfo() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/org/info"),
        method: "post",
        params: this.$http.adornParams({
          id: this.$orgId
        }),
      }).then(({ data }) => {
            if (data && data.code == 0) {
              this.villageType = data.sysOrg.villageType
            }
          }
      );
    },
    getDataList(type, name) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqaffairsopen/list"),
        method: "post",
        params: this.$http.adornParams({
          type: type,
          page: 1,
          limit: 3,
          orgId: this.$orgId,
          resId: this.$userId,
          isPublic: 1
        }),
      }).then(({ data }) => {
            if (data && data.code == 0) {
              data.page.list.map(item => {
                if (item.createTime) {
                  item['create'] = formatterDate(newDate(item.createTime))
                }
              })
              if (name == '党务公开') {
                this.partyList = data.page.list;
              }
              if (name == '村务公开') {
                this.villageList = data.page.list;
              }
              if (name == '财务公开'){
                this.financialList = data.page.list;
              }
              if (name == '全部') {
                this.newList = data.page.list
              }
              // 加载状态结束
              this.loading = false;
              this.$toast.clear();
            }
            this.$toast.clear();
          },
          (err) => {
            this.$toast.clear();
          }
      );
    },
    workTargetList(type, title) {
      this.$router.push({
        name: "openVillage",
        query: { title: title, type: type },
      });
    },
    navToFun(userId, title) {
      this.$router.push({
        name: "openLabour",
        query: { userId, title },
      });
    },
  },
  mounted() {
    this.getType('党务公开')
    this.getType('村务公开')
    this.getType('财务公开')
    this.getInfo()
  },
};
</script>

<style scoped lang="scss">
.notice {
  width: 100vw;
  //height: 100vh;
  overflow: hidden;
  background: url('~@/assets/img/partyBack1.png') no-repeat;
  background-size: 100%;
  .partyLogo {
    width: 800px;
    //height: 340px;
    margin-top: 27%;
    margin-left: -25px;
  }
  .workGoal {
    //height: 980px;
    padding: 0 35px;
    //overflow-y: scroll;
    .module {
      margin-bottom: 10px;
    }
    .title {
      position: relative;
      height: 88px;
      line-height: 88px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      margin-bottom: 10px;
      span:nth-child(2) {
        position: absolute;
        right: 6%;
        font-size: 26px;
        font-weight: 400;
        color: #999999;
      }
    }
    .content {
      height: 148px;
      display: flex;
      align-items: center;
      position: relative;
      &:last-child{
        margin-bottom: 100px;
      }
      .content-img {
        width: 140px;
        height: 140px;
      }
      .content-logo {
        position: absolute;
        top: 2px;
        left: -5px;
        width: 72px;
        height: 44px;
      }
      .content-text {
        margin-left: 40px;
        .top-text {
          color: #333333;
          font-size: 28px;
          font-weight: 600;
          width: 450px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .center-text {
          color: #666;
          font-size: 26px;
          margin-top: 20px;
          width: 450px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        .bottom-text{
          display: flex;
          margin-top: 25px;
          font-size: 24px;
          color: #999999;
          .left-time {
            img {
              width: 28px;
              height: 28px;
            }
            span {
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              margin-left: 4px;
              vertical-align: middle;
            }
          }
          .right-look {
            flex: 1;
            margin-left: 40px;
            img {
              width: 28px;
              height: 28px;
            }
            span {
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              margin-left: 4px;
              vertical-align: middle;
              i {
                font-size: 24px;
                color: #666666;
                font-style: normal;
              }
            }
          }
        }
      }
      .content-icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .van-icon-arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: 600;
  }
  .divider {
    height: 1px;
    margin: 24px 0;
    background: rgba(0,0,0,0.1);
  }
}
</style>
